import React from "react"
import { Link, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Location } from '@reach/router';


const Layout = ({ data, ...props }) => {

  const { title, children, location } = props
  const [toggleNav, setToggleNav] = React.useState(false)
  const { logo } = data
  let showcta = location.pathname === '/about' || location.pathname === '/contact' ? false : true || location.pathname === '/thanks' ? false : true || location.pathname === '/pay' ? false : true
  let showmailchimp = location.pathname === '/pay' ? false : true

  console.log(location.pathname)
  return (
    <div className={`site-wrapper ${toggleNav ? `site-head-open` : ``}`}>
      <header className="site-head">
        <div className="site-head-container">
          <a
            className="nav-burger"
            href={`#`}
            onClick={() => setToggleNav(!toggleNav)}
          >
            <div
              className="hamburger hamburger--collapse"
              aria-label="Menu"
              role="button"
              aria-controls="navigation"
            >
              <div className="hamburger-box">
                <div className="hamburger-inner" />
              </div>
            </div>
          </a>
          <nav id="swup" className="site-head-left">
            <ul className="nav" role="menu">
              <li className="nav-home" role="menuitem">
                <Link to={`/`}>Home</Link>
              </li>
              <li className="nav-about" role="menuitem">
                <Link to={`/about`}>About</Link>
              </li>
              <li className="nav-about" role="menuitem">
                <Link to={`/pay`}>Pay</Link>
              </li>
              <li className="nav-about" role="menuitem">
                <Link to={`/contact`}>Contact</Link>
              </li>
            </ul>
          </nav>
          <div className="site-head-center">
            <Link className="site-head-logo" to={`/`}>
              <Img className="kg-image" fixed={logo.nodes[0].fixed} />
            </Link>
          </div>
          <div className="site-head-right">
            <div className="social-links">
              <a
                href="https://www.facebook.com/louiseholdenequine"
                title="Facebook"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook
              </a>
              {/* <a
                href="https://twitter.com"
                title="Twitter"
                target="_blank"
                rel="noopener noreferrer"
              >
                Twitter
              </a> */}
              <a
                href="/rss.xml"
                title="RSS"
              >
                RSS
              </a>
              <a href="tel:07917341804">Phone</a>
            </div>
          </div>
        </div>
      </header>
      <main id="site-main" className="site-main">
        <div id="swup" className="transition-fade">
          {children}
        </div>
      </main>
      {showcta &&
        <section className="hireme">
          <h3>Coaching you and your horse(s)?</h3>
        <p className="h5" style={{ fontWeight: `normal`, }}>Maybe I can help, I am an experienced freelance instructor (BHS Stage 3 coach) based in Devizes. I am available for lessons, competing, schooling and rehabilitation. I would love to hear from you. You can read more <Link to={`/about`}>about me here</Link> or <Link to={`/contact`}>contact me</Link> to have a chat.</p>
        </section>
      }
      {showmailchimp &&
      <section className="signup">
        <div id="mc_embed_signup">
          <form action="https://gmail.us20.list-manage.com/subscribe/post?u=273f62e95e1f8f54f13d0a14c&amp;id=6e084a9211" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
            <div id="mc_embed_signup_scroll">
              <h2>Stay in touch</h2>
              <p>Sign up for updates from me, no spam just occasional updates on what I am doing.</p>
              <div className="mc-field-group">
                <input type="email" placeholder="Your Email Address" name="EMAIL" className="required email" id="mce-EMAIL"/>
              </div>
              <div id="mce-responses" className="clear">
              <div className="response" id="mce-error-response" style={{
                display: `none`,
              }}></div>
              <div className="response" id="mce-success-response" style={{
                display: `none`,
              }}></div>
              </div>
            <div style={{
              position: `absolute`, left: `-5000px`,
            }} aria-hidden="true">
                <input type="text" name="b_273f62e95e1f8f54f13d0a14c_6e084a9211" tabIndex="-1" value="" />
              </div>
              <div className="clear">
                <button type="submit" id="mc-embedded-subscribe" className="button">Subscribe</button>
              </div>
            </div>
          </form>
          </div>
      </section>
      }
      <footer className="site-foot">
        <p>For all enquiries please call or text <a href="tel:07917341804">07917 341804</a></p>
        <p>&copy; {new Date().getFullYear()} <Link to={`/`}>{title}</Link></p>
      </footer>
    </div>
  )
}

export default props => (
  <Location>{locationProps => 
    <StaticQuery
      query={graphql`
        query {
        logo: allImageSharp(filter: { fixed: { originalName: { eq: "logo.png" } } }) {
          nodes {
            fixed(width: 150) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      `}
      render={data => <Layout data={data} {...props} {...locationProps} />}
    />
      }
  </Location>
)

// export default Layout
